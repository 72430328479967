import React from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import AmberScore from "../components/reusable/AmberScore";
import PriceTable from "../components/pricing/PriceTable";
import { Component } from "react";
import ReactTooltip from 'react-tooltip';
class Pricing extends Component {

    constructor(props){  
        super(props);  
        if(typeof window !== `undefined`){
            window.location.href = 'https://www.infeedo.ai/pricing'; 
        }
    }  

   
    render(){
        return (
            true ? null :
            <Layout page = 'pricing'>
                <SEOComponent title="Amber by inFeedo | Pricing" />
                <PriceTable />
                <ReactTooltip place="right" effect="solid" html={true} class='bg-blue' />
                <AmberScore />
            </Layout>
        )
    }

}

export default Pricing