import React from "react";
import ScheduleDemoButton from '../scheduleDemo/ScheduleDemoButton';
import G2_crowd_img from '../../../static/images/medal_g2_asia_pacific.png'
import image_url_map from "../../../static/images/constants/image_constants";
import PrivacyPolicyDisclaimer from "./PrivacyPolicyDisclaimer";
const amber_img = image_url_map['amber_blue_bg'];

const AmberScore = ({page}) => (
    <div className="has-text-centered bg-amberscore padding-20-mobile">
        <div className="pd-10">
            <div className="is-hidden-tablet">
                <br />
                <br />
            </div>
            <h3 className="is-size-3-tablet is-size-5-mobile has-text-weight-bold pd-bottom-40">Our customers and their employees love Amber!</h3>
            <div className="g2-crowd-div pd-bottom-40">
                <div className="g2-crowd-g2ScoreDiv">
                    <img className="g2-crowd-img" src={G2_crowd_img} alt="infeedo logo white"></img>
                    <h1 className="has-text-weight-bold is-size-7-mobile"> G2 Ranked Leading Employee Engagement <br></br>Solution in APAC</h1>
                </div>
                <div>
                    <img className="g2-crowd-amber-img" src={amber_img} alt="infeedo logo white"></img>
                    <h1 className="has-text-weight-bold is-size-7-mobile">Employees have scored<br></br> her 4.46/5</h1>
                </div>
            </div>
            <p className="is-size-4 is-size-6-mobile">We are certain you will too.</p>
            <br /><br />
            {page === 'customers' ? null
             :
             <>
             <div className="is-hidden-mobile centered-input"><ScheduleDemoButton initial_email_enabled={true} /></div>
            <div className="is-hidden-tablet"><ScheduleDemoButton /></div>
            <div className="is-hidden-tablet">
                <br />
                <br />
            </div>
            <div className="footer-disclaimer">
                <PrivacyPolicyDisclaimer />
            </div>
             </>}
            
        </div>
    </div>
)

export default AmberScore

